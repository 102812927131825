@font-face {
  font-family: "Public Sans";
  font-weight: 400;
  font-style: normal;
  src: url("/fonts/PublicSans/PublicSans-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Public Sans";
  font-weight: 500;
  font-style: normal;
  src: url("/fonts/PublicSans/PublicSans-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Public Sans";
  font-weight: 600;
  font-style: normal;
  src: url("/fonts/PublicSans/PublicSans-SemiBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Public Sans";
  font-weight: 700;
  font-style: normal;
  src: url("/fonts/PublicSans/PublicSans-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Public Sans";
  font-weight: normal;
  font-style: italic;
  src: url("/fonts/PublicSans/PublicSans-Italic.ttf");
  font-display: swap;
}
